<template>
    <legend class="
        block

        text-xl
        font-semibold

        text-gray-700

        mb-2
    "><slot /></legend>
</template>
